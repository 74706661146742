<template>
  <v-layout class="flex-column px-5" v-if="agency">
    <v-container class="mt-5">
      <div>
        <span class="agency-detail-title mt-15"> {{ agency.name }} </span>
        <v-btn rounded color="#472583" dark icon class="mb-4" @click="toEdit">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </div>
      <v-select
        :items="statusOptions"
        v-model="agency.status"
        rounded
        background-color="#472583"
        dark
        style="width: 150px;"
        @change="onChangeStatus"
      />
    </v-container>
    <v-row class="mt-3 mb-15">
      <div style="display: flex; flex-direction: column;">
        <v-card width="400" height="400" class="pa-8">
          <agency-avatar
            :img="agency.image"
            :size="340"
            :name="agency.name"
            v-if="agency && agency.name"
          />
        </v-card>
        <span class="agency-subtitle mt-8">Cause</span>
        <div class="d-flex flex-wrap" style="max-width: 400px;">
          <site-icon
            :name="icon"
            type="Cause"
            v-for="(icon, key) in agency.causes"
            :key="key"
            class="mr-3 mt-3"
          />
        </div>
        <span class="agency-subtitle mt-8">Contacts</span>
        <div>
          <div class="ma-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2" v-bind="attrs" v-on="on" color="#fdbc3a"
                  >mdi-phone-classic</v-icon
                >
              </template>
              <span>Agency Phone</span>
            </v-tooltip>
            {{ formatPhoneNumber(agency.phone) }}
          </div>
          <div class="ma-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2" v-bind="attrs" v-on="on" color="#fdbc3a">
                  mdi-email
                </v-icon>
              </template>
              <span>Agency Email</span>
            </v-tooltip>
            {{ agency.email }}
          </div>
          <div class="ma-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2" v-bind="attrs" v-on="on" color="#fdbc3a">
                  mdi-account-box
                </v-icon>
              </template>
              <span>Agency Contact</span>
            </v-tooltip>
            {{ agency.contact_person }}
          </div>
          <div class="ma-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2" v-bind="attrs" v-on="on" color="#fdbc3a">
                  mdi-account-box
                </v-icon>
              </template>
              <span>Agency Contact Title</span>
            </v-tooltip>
            {{ agency.contact_title }}
          </div>
        </div>
        <span class="agency-subtitle mt-8">Location</span>
        <div
          class="ma-2"
          style="display: flex; flex-direction: row; align-items: flex-start"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-2" v-bind="attrs" v-on="on" color="#fdbc3a"
                >mdi-map-marker</v-icon
              >
            </template>
            <span>Agency Location</span>
          </v-tooltip>
          <div style="display: flex; flex-direction: column;">
            <span>{{ agency.address }}</span>
            <span>{{ agency.city }}, {{ agency.state }} {{ agency.zip }}</span>
          </div>
        </div>
        <div>
          <GmapMap
            :center="center"
            :zoom="13"
            map-type-id="terrain"
            class="map"
          >
            <GmapMarker :position="center" />
          </GmapMap>
        </div>
        <span class="agency-subtitle mt-8">Links</span>
        <div>
          <div class="ma-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2" v-bind="attrs" v-on="on" color="#fdbc3a">
                  mdi-link-variant
                </v-icon>
              </template>
              <span>Agency Website</span>
            </v-tooltip>
            <a :href="agency.website" target="_blank">Website</a>
          </div>
          <div class="ma-2" v-if="agency.facebook">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2" v-bind="attrs" v-on="on" color="#fdbc3a"
                  >mdi-facebook</v-icon
                >
              </template>
              <span>Facebook</span>
            </v-tooltip>
            <a :href="agency.facebook" target="_blank">Facebook</a>
          </div>
          <div class="ma-2" v-if="agency.twitter">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2" v-bind="attrs" v-on="on" color="#fdbc3a"
                  >mdi-twitter</v-icon
                >
              </template>
              <span>Twitter</span>
            </v-tooltip>
            <a :href="agency.twitter" target="_blank">Twitter</a>
          </div>
        </div>
      </div>
      <v-col style="display: flex; flex-direction: column;" class="ml-3">
        <span class="agency-subtitle">Who We Are</span>
        <span class="agency-description">{{ agency.who_we_are }}</span>
        <span class="agency-subtitle mt-15">What We Do</span>
        <span class="agency-description"> {{ agency.what_we_do }}</span>
        <span class="agency-subtitle mt-15">Photos</span>
        <agency-photo-viewer :agency_id="$route.params.id" />
      </v-col>
    </v-row>
  </v-layout>
</template>
<script>
import AgencyAvatar from "@/components/agency/AgencyAvatar.vue";
import AgencyPhotoViewer from "@/components/agency/AgencyPhotoViewer.vue";
import { mapActions } from "vuex";
import SiteIcon from "@/components/common/SiteIcon.vue";
export default {
  components: { AgencyPhotoViewer, AgencyAvatar, SiteIcon },
  data() {
    return {
      center: { lat: 42.278767, lng: -71.420351 },
      agency: null,
      loading: false,
      statusOptions: ["Pending", "Available"],
    };
  },
  methods: {
    ...mapActions("agency", {
      getAgency: "getAgency",
      editAgency: "editAgency",
    }),
    loadData() {
      this.loading = true;
      this.getAgency(this.$route.params.id)
        .then((data) => {
          this.loading = false;
          this.agency = data;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error);
          }
        });
    },
    onChangeStatus() {
      console.log(this.agency.status);
      if (this.agency) {
        console.log(this.agency);
        this.loading = true;
        this.editAgency({ _id: this.agency._id, status: this.agency.status })
          .then(() => {
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            if (error.response != undefined && error.response.status === 401) {
              this.tokenDialog = true;
            } else {
              console.log(error);
            }
          });
      }
    },
    toEdit() {
      this.$router.push({
        name: "edit-agency",
        params: { id: this.$route.params.id },
      });
    },
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return str;
    },
  },
  watch: {
    "$route.params.id": function() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
<style>
.agency-detail-title {
  color: #472583;
  font-size: 40px;
  font-weight: bold;
}
.agency-subtitle {
  color: #472583;
  font-size: 24px;
  font-weight: 500;
}
.agency-description {
  color: #472583;
  font-size: 18px;
}

.map {
  width: 400px;
  height: 300px;
}
</style>
